import React from "react"
import { css } from "@emotion/core"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import PostItem from "../components/post-item"
import SEO from "../components/seo"

export default ({ data, pageContext, location }) => {
  const {
    html,
    excerpt,
    frontmatter: { title, date, datetime, tags, ogimage },
  } = data.markdownRemark
  const { previous, next } = pageContext
  const ogImagePath =
    ogimage && ogimage.childImageSharp && ogimage.childImageSharp.fixed.src
  return (
    <Layout location={location}>
      <SEO
        title={title}
        description={excerpt}
        image={ogImagePath}
        pathname={location.pathname}
        article
      />
      <PostItem
        title={title}
        date={date}
        dateTime={datetime}
        tags={tags}
        html={html}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 50px;
        `}
      >
        <div
          css={css`
            width: 385px;
            text-align: right;
          `}
        >
          {next && (
            <>
              新しい投稿
              <br />
              <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
            </>
          )}
        </div>
        <div
          css={css`
            width: 10px;
            height: 10px;
            margin: 10px;
            border-radius: 50%;
            background-color: #000;
          `}
        />
        <div
          css={css`
            width: 385px;
            text-align: left;
          `}
        >
          {previous && (
            <>
              古い投稿
              <br />
              <Link to={previous.fields.slug}>
                {previous.frontmatter.title}
              </Link>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        date(formatString: "YYYY年MM月DD日")
        datetime: date(formatString: "YYYY-MM-DD")
        ogimage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      fields {
        slug
      }
      excerpt
    }
  }
`
